var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "text-right mt-5 mr-2 mb-4"
  }, [_c('router-link', {
    staticClass: "btn btn-primary btn-sm ml-2",
    attrs: {
      "to": {
        name: 'trust-inheritance.moveable-property.create',
        query: {
          hibah_id: _vm.hibahId
        }
      }
    }
  }, [_vm._v("+ " + _vm._s(_vm.$t("property")))])], 1), _vm.properties && _vm.properties.length == 0 ? _c('div', {
    staticClass: "p-4 text-center"
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("trust.no-prop")))])]) : _vm._e(), _c('div', {
    staticClass: "row",
    staticStyle: {
      "position": "relative"
    }
  }, _vm._l(_vm.properties, function (property, index) {
    return _c('div', {
      key: index,
      staticClass: "col-md-12"
    }, [_c('div', {
      staticClass: "pl-3 pr-3 mt-2"
    }, [_c('div', {
      staticClass: "row"
    }, [_c('div', {
      staticClass: "col-md-6"
    }, [_c('strong', [_c('router-link', {
      attrs: {
        "to": {
          name: 'trust-inheritance.moveable-property.details',
          query: {
            hibah_id: _vm.hibahId,
            property_id: property.id
          }
        }
      }
    }, [_vm._v(" " + _vm._s(index + 1 + ". " + property.property_type) + " ")])], 1)])]), property.property_type == 'Bank' ? _c('div', {
      staticClass: "row pl-3"
    }, [_c('div', {
      staticClass: "col-md-6"
    }, [_c('strong', [_vm._v(_vm._s(_vm.$t("bank-type")))]), _vm._v(": " + _vm._s(property.bank_type) + " ")]), _c('div', {
      staticClass: "col-md-6"
    }, [_c('strong', [_vm._v(_vm._s(_vm.$t("bank-branches")))]), _vm._v(": " + _vm._s(property.bank_branch) + " ")]), _c('div', {
      staticClass: "col-md-6"
    }, [_c('strong', [_vm._v(_vm._s(_vm.$t("acc-no-member")))]), _vm._v(": " + _vm._s(property.bank_account_no) + " ")]), _c('div', {
      staticClass: "col-md-6"
    }, [_c('strong', [_vm._v(_vm._s(_vm.$t("document")))]), _vm._v(": "), _c('router-link', {
      attrs: {
        "to": {
          name: 'trust-inheritance.moveable-property.attachment',
          query: {
            hibah_id: _vm.hibahId,
            property_id: property.id,
            amendment_id: _vm.amendmentId
          }
        }
      }
    }, [_vm._v(_vm._s(property.total_documents) + " " + _vm._s(_vm.$t("document")))])], 1)]) : _vm._e(), property.property_type == 'Kenderaan' ? _c('div', {
      staticClass: "row pl-3"
    }, [_c('div', {
      staticClass: "col-md-6"
    }, [_c('strong', [_vm._v(_vm._s(_vm.$t("vehicle-type")))]), _vm._v(": " + _vm._s(property.transport_type) + " ")]), _c('div', {
      staticClass: "col-md-6"
    }, [_c('strong', [_vm._v(_vm._s(_vm.$t("made-in")))]), _vm._v(": " + _vm._s(property.transport_manufacturer) + " ")]), _c('div', {
      staticClass: "col-md-6"
    }, [_c('strong', [_vm._v(_vm._s(_vm.$t("model-name")))]), _vm._v(": " + _vm._s(property.transport_model) + " ")]), _c('div', {
      staticClass: "col-md-6"
    }, [_c('strong', [_vm._v(_vm._s(_vm.$t("org.reg-no")))]), _vm._v(": " + _vm._s(property.transport_registration_number) + " ")])]) : _vm._e(), property.property_type == 'Saham' ? _c('div', {
      staticClass: "row pl-3"
    }, [_c('div', {
      staticClass: "col-md-6"
    }, [_c('strong', [_vm._v(_vm._s(_vm.$t("acc-no-member")))]), _vm._v(": " + _vm._s(property.account_number) + " ")]), _c('div', {
      staticClass: "col-md-6"
    }, [_c('strong', [_vm._v(_vm._s(_vm.$t("org-name")))]), _vm._v(": " + _vm._s(property.institution_name) + " ")])]) : _vm._e(), property.property_type == 'Syarikat' ? _c('div', {
      staticClass: "row pl-3"
    }, [_c('div', {
      staticClass: "col-md-6"
    }, [_c('strong', [_vm._v(_vm._s(_vm.$t("co-regno")))]), _vm._v(": " + _vm._s(property.company_registration_number) + " ")]), _c('div', {
      staticClass: "col-md-6"
    }, [_c('strong', [_vm._v(_vm._s(_vm.$t("org-name")))]), _vm._v(": " + _vm._s(property.institution_name) + " ")])]) : _vm._e(), property.property_type == 'Simpanan' ? _c('div', {
      staticClass: "row pl-3"
    }, [_c('div', {
      staticClass: "col-md-6"
    }, [_c('strong', [_vm._v(_vm._s(_vm.$t("acc-no-member")))]), _vm._v(": " + _vm._s(property.account_number) + " ")]), _c('div', {
      staticClass: "col-md-6"
    }, [_c('strong', [_vm._v(_vm._s(_vm.$t("org-name")))]), _vm._v(": " + _vm._s(property.institution_name) + " ")])]) : _vm._e(), property.property_type == 'Insurans' ? _c('div', {
      staticClass: "row pl-3"
    }, [_c('div', {
      staticClass: "col-md-6"
    }, [_c('strong', [_vm._v(_vm._s(_vm.$t("policy-no")))]), _vm._v(": " + _vm._s(property.policy_number) + " ")]), _c('div', {
      staticClass: "col-md-6"
    }, [_c('strong', [_vm._v(_vm._s(_vm.$t("org-name")))]), _vm._v(": " + _vm._s(property.institution_name) + " ")])]) : _vm._e(), _c('div', {
      staticClass: "table-responsive pl-3 mt-3 mb-4"
    }, [_c('table', {
      staticClass: "table table-sm"
    }, [_c('thead', [_c('tr', [_c('th', [_vm._v("#")]), _c('th', [_vm._v(_vm._s(_vm.$t("name")))]), _c('th', [_vm._v(_vm._s(_vm.$t("mykad")))]), _c('th', [_vm._v(_vm._s(_vm.$t("relationship")))]), _c('th', [_vm._v(_vm._s(_vm.$t("part")))])])]), _c('tbody', [_vm._l(property.property_receivers, function (pr, index2) {
      return _c('tr', {
        key: index2
      }, [_c('td', [_c('router-link', {
        attrs: {
          "to": {
            name: 'trust-inheritance.moveable-property.receiver',
            query: {
              hibah_id: _vm.hibahId,
              property_id: property.id,
              amendment_id: _vm.amendmentId
            }
          }
        }
      }, [_vm._v(" " + _vm._s(index2 + 1) + " ")])], 1), _c('td', [_vm._v(_vm._s(pr.receiver.name))]), _c('td', [_vm._v(_vm._s(pr.receiver.ic_number))]), _c('td', [_vm._v(_vm._s(pr.receiver.relationship))]), _c('td', [_vm._v(_vm._s(pr.portion))])]);
    }), property.property_receivers.length == 0 ? _c('tr', [_c('td', {
      staticClass: "text-center",
      attrs: {
        "colspan": "5"
      }
    }, [_c('h6', {
      staticClass: "mt-3 mb-3"
    }, [_vm._v(_vm._s(_vm.$t("trust.no-beneficiary")))])])]) : _vm._e()], 2)])])]), _c('div', {
      staticClass: "text-right"
    }, [_vm.hibah && [0, 1, 2].includes(_vm.hibah.status) || _vm.amendmentId ? _c('b-button', {
      staticClass: "btn-sm btn-danger",
      on: {
        "click": function ($event) {
          return _vm.deleteProperty(property.id, index);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("remove")) + " ")]) : _vm._e()], 1), _c('hr', {
      staticClass: "primary"
    })]);
  }), 0)]);
}
var staticRenderFns = []

export { render, staticRenderFns }